<template>
  <div
    v-if="displayInDashboard == true"
    class="col-md-6 col-xl-8 col-lg-8 order-lg-3 order-xl-1"
    v-show="gMaps.show && canShowDash"
  >
    <KTPortlet
      :fullContent="true"
      v-bind:title="'Service Overview'"
      adBodyStyle="padding: 0;"
      v-if="gMaps.show && canShowDash"
    >
      <template v-slot:body="{ height }">
        <GoogleMaps
          :height="height"
          extraMapStyle="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;"
          :mapConfig="gMaps.mapConfig"
          :apiKey="gMaps.apiKey"
          :markers="gMaps.markers"
          :autoCenterToMarkers="true"
        />
      </template>
    </KTPortlet>
  </div>
  <KTPortlet title="My Services" v-else :fullContent="false">
    <template slot="body">
      <Loader
        v-if="services === null"
        :newStyle="2"
        :fields="['Status', 'Name', 'Location', 'Price']"
      ></Loader>
      <div
        v-else-if="services.length === 0"
        style="width: 100%; text-align: center"
      >
        No services to display
      </div>
      <div v-else>
        <!-- <v-container fluid>
          <v-row dense>
            <v-col style="display: flex;">
              <v-card class="mx-auto" width="400" v-for="item of items" v-bind:key="item.id">
                <div style="padding: 10px; height: 100px;">
                  <v-img
                    class="text-success align-end"
                    style="opacity: 0.3;"
                    height="100px"
                    :src="$tools.getServiceDetails(item.servicePlanId).img"
                  ></v-img>
                </div>

                <v-card-title style="text-align: center; display: block;">{{!$tools.isNullOrUndefined($tools.getServiceDetails(item.servicePlanId).name) ? $tools.getServiceDetails(item.servicePlanId).name : item.Name}}</v-card-title>
                <div v-if="!$tools.isNullOrUndefined($tools.getServiceDetails(item.servicePlanId).name)">
                  {{item.Name}}
                </div>
                -- <v-card-actions>
                  <b-button
                    variant="success"
                    style="margin: 0 auto;"
                  >Pay {{$tools.formatCurrency(viewInvoice.total - viewInvoice.amountPaid, 2, '.', ' ', viewInvoice.currencyCode)}} with linked card</b-button>
                </v-card-actions> -
              </v-card>
            </v-col>
          </v-row>
        </v-container>-->
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th>Status</b-th>
              <b-th>Name</b-th>
              <b-th>Location</b-th>
              <b-th>Price</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in pagedItems" v-bind:key="item.id">
              <b-th>
                <v-chip
                  class="ma-2"
                  :style="
                    `background: var(--${item.Status.class}); color: #fff;`
                  "
                >
                  <v-icon left :class="item.Status.class" color="#fff">{{
                    item.Status.icon
                  }}</v-icon>
                  {{ item.Status.text }}
                </v-chip>
              </b-th>
              <b-td>{{ item.Name }}</b-td>
              <b-td>{{ item.Location }}</b-td>
              <b-td>{{ item.Price }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!-- <b-table
          :striped="true"
          :bordered="true"
          :borderless="true"
          :dark="false"
          :items="items"
          :fields="tableFields"
          head-variant="dark"
        >
          <template v-slot:cell(Status)="row">
            <v-chip
              class="ma-2"
              :style="`background: var(--${row.item.Status.class}); color: #fff;`"
            >
              <v-icon left :class="row.item.Status.class" color="#fff">{{row.item.Status.icon}}</v-icon>
              {{row.item.Status.text}}
            </v-chip>
          </template>
        </b-table>-->
      </div>
    </template>
    <template slot="foot">
      <div>
        <div v-if="services !== null">
          <v-pagination
            v-if="items.length > perPage"
            align="center"
            v-model="currentPage"
            :total-visible="7"
            circle
            :length="Math.ceil(items.length / perPage)"
            :per-page="perPage"
          ></v-pagination>
        </div>
      </div>
    </template>
  </KTPortlet>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
import * as Telecoms from "../bettertelecoms/routerConfig";

import GoogleMaps from "@/views/partials/content/GoogleMaps.vue";

const SSices = [Telecoms];
const findServiceUrl = service => {
  for (let ssI of SSices) {
    for (let ssService of ssI.default.serviceSelector.services) {
      if (ssService.servicePlanId.indexOf(service.servicePlanId) >= 0) {
        return ssI.default.serviceSelector.defaultUrl.replace(
          "{ID}",
          service.id
        );
      }
    }
  }
  return null;
};

export default {
  props: ["displayInDashboard"],
  components: {
    GoogleMaps,
    KTPortlet,
    Loader
  },
  data() {
    return {
      gMaps: {
        show: false,
        apiKey: "AIzaSyCIqydv4B7_KH8U1nxQva57NbaZQHKZCwk",
        mapConfig: {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 8
        },
        markers: []
      },
      currentPage: 1,
      perPage: 20,
      services: null,
      tableFields: ["Status", "Name", "Location", "Price"]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    canShowDash() {
      return (this.layoutConfig().hideMenus || []).indexOf("My Account") < 0;
    },
    pagedItems() {
      let listToReturn = [];

      let maxPage = this.perPage * this.currentPage;
      if (maxPage > this.items.length) maxPage = this.items.length;
      for (let i = this.perPage * (this.currentPage - 1); i < maxPage; i++) {
        listToReturn.push(this.items[i]);
      }

      return listToReturn;
    },
    worthWhileServicesList() {
      if (this.services === null) return [];
      return this.services.filter(x => {
        if (x.status === 'active') return true;
        if (x.status === 'suspended') return true;
        return false;
      });
    },
    items() {
      if (this.services === null) return [];
      let dataToReturn = [];
      for (let row of this.worthWhileServicesList) {
        dataToReturn.push({
          Status: this.$tools.getServiceStatusInfo(row.status),
          Name: row.name,
          Location: !this.$tools.isNullOrUndefined(row.street1)
            ? row.street1 +
              (!this.$tools.isNullOrUndefined(row.city) ? `, ${row.city}` : "")
            : "",
          Price:
            row.price === 0
              ? "FREE"
              : this.$tools.formatCurrency(
                  row.price,
                  2,
                  ".",
                  " ",
                  row.currencySym
                )
        });
      }
      const orders = {
        0: 1,
        1: 5,
        2: -1,
        3: 10,
        4: 3,
        5: -2,
        6: 2,
        7: 7
      };
      for (let i = 0; i < dataToReturn.length - 1; i++) {
        for (let j = i + 1; j < dataToReturn.length; j++) {
          if (
            orders[dataToReturn[i].Status.code] <
            orders[dataToReturn[j].Status.code]
          ) {
            let tmp = dataToReturn[i];
            dataToReturn[i] = dataToReturn[j];
            dataToReturn[j] = tmp;
          }
        }
      }
      return dataToReturn;
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`evu-service`);
    this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "My Account" },
      { title: "Services" }
    ]);

    let self = this;
    self.$eventBus.on(`evu-service`, () => self.updateServices(true));
    self.$eventBus.on(`cache-crm:services`, () => self.updateServices(false));
    self.updateServices(); /*
    this.$cache
      .get("my-account:services")
      .then(cacheOfServices => {
        if (cacheOfServices) {
          return (self.$data.services = cacheOfServices);
        }
        ApiService.get("crm/services")
          .then(({ data }) => {
            self.$data.currentPage = 1;
            self.$data.services = data;
            self.$cache.set("my-account:services", data);
          })
          .catch(({ response }) => {
            self.$log.error(response);
          });
      })
      .catch(x => {
        self.$log.error(x);
        console.error("Error loading content");
      });*/
  },
  methods: {
    updateServices(bumpacache = false) {
      let self = this;
      this.$api
        .getWithCache(
          self,
          "crm",
          `${this.$store.getters.clientId}/services`,
          "crm:services",
          bumpacache
        )
        .then(data => {
          self.$data.currentPage = 1;
          self.$data.services = data;

          if (
            !self.$tools.isNullOrUndefined(self.$route.params.id) &&
            self.$route.path.endsWith("/Go")
          ) {
            for (let service of data) {
              if (`${service.id}` === `${self.$route.params.id}`) {
                let serviceUrl = findServiceUrl(service);
                if (serviceUrl !== null) return self.$router.push(serviceUrl);
              }
            }
          }

          for (let service of data) {
            if (self.$tools.isNullOrUndefined(service.geo)) continue;
            if (service.status !== "active") continue;
            self.gMaps.markers.push({
              id: service.id,
              position: { lat: service.geo.lat, lng: service.geo.lng }
            });
          }
          if (self.gMaps.markers.length > 0) self.$data.gMaps.show = true;
        })
        .catch(response => {
          self.$log.error(response);
        });
    }
  }
};
</script>
