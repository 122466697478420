<template>
  <div
    :id="mapId"
    :style="`width: 100%; height: ${
      height || 500
    }px; overflow: hidden; ${extraMapStyle}`"
  >
    <div
      class="google-map"
      ref="googleMap"
      style="width: 100%; height: 100%"
    ></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>


<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import { v4 as UUID } from "uuid";

export default {
  props: {
    height: Number,
    mapConfig: Object,
    apiKey: String,
    extraMapStyle: String,
    markers: Array,
    //zoomBounds: Object,
    autoCenterToMarkers: Boolean,
  },

  data() {
    return {
      google: null,
      map: null,
      mapId: "google-map-AA",
    };
  },

  async mounted() {
    this.mapId = `google-map-${UUID()}`;
    this.mapConfig = this.mapConfig || {};
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey,
    });
    this.google = googleMapApi;
    this.initializeMap();
  },

  methods: {
    getBoundsZoomLevel(boundNE, boundSW, mapDim) {
      var WORLD_DIM = { height: 256, width: 256 };
      var ZOOM_MAX = 21;

      function latRad(lat) {
        var sin = Math.sin((lat * Math.PI) / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      var latFraction = (latRad(boundNE.lat) - latRad(boundSW.lat)) / Math.PI;

      var lngDiff = boundNE.lng - boundSW.lng;
      var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

      var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    },
    initializeMap() {
      const self = this;
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

      self.$nextTick(() => {
        if (!self.$tools.isNullOrUndefined(self.markers)) {
          let bestLeft = 99999;
          let bestRight = -99999;
          let bestTop = 99999;
          let bestBottom = -99999;
          for (let marker of self.markers) {
            new self.google.maps.Marker({
              position: marker.position,
              marker: marker,
              map: self.map,
              //icon: POINT_MARKER_ICON_CONFIG,
            });
            if (marker.position.lat < bestLeft) bestLeft = marker.position.lat;
            if (marker.position.lat > bestRight)
              bestRight = marker.position.lat;
            if (marker.position.lng < bestTop) bestTop = marker.position.lng;
            if (marker.position.lng > bestBottom)
              bestBottom = marker.position.lng;
          }
          if (self.autoCenterToMarkers === true) {
            let cLat = (bestLeft + bestRight) / 2;
            let cLng = (bestTop + bestBottom) / 2;
            self.map.setCenter({ lat: cLat, lng: cLng });
          }
          self.map.setZoom(
            self.getBoundsZoomLevel(
              { lat: bestRight, lng: bestTop },
              { lat: bestLeft, lng: bestBottom },
              {
                height: document.getElementById(self.mapId).clientHeight,
                width: document.getElementById(self.mapId).clientWidth,
              }
            )+4
          );
        }
        /*if (!this.$tools.isNullOrUndefined(this.zoomBounds)) {
        this.$nextTick(() => {
          self.mapConfig.zoom = self.getBoundsZoomLevel(self.zoomBounds, {
            height: document.getElementById(self.mapId).clientHeight,
            width: document.getElementById(self.mapId).clientWidth,
          });
          self.map.setZoom(self.mapConfig.zoom);
        });
      }*/
      });
    },
  },
};
</script>
